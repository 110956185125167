import React from "react"

import SEO from "src/components/SEO"
import AboutContentNyc from "./AboutContentNyc"
import AboutContentLa from "./AboutContentLa"
import styled from "styled-components"

export default function AboutContent() {
  const city = process.env.GATSBY_CITY
  const Content =
    { nyc: AboutContentNyc, la: AboutContentLa }[city] ?? (() => null)

  return (
    <>
      <SEO title="About" />
      <Content />
      <CrashTestDiv
        onClick={() => {
          throw new Error("Crash Test")
        }}
      />
    </>
  )
}

const CrashTestDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 80px;
`
