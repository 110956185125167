import React from "react"
import styled from "styled-components"

import StandardContent from "src/components/StandardContent"
import PageTitleWithImage from "src/components/PageTitleWithImage"
import Markdown from "src/components/Markdown"
import ContentPageStyles from "src/styles/ContentPageStyles"
import logo from "src/images/nyc-vintage-map@2x.png"
import aboutNycMd from "src/content/aboutNyc-2020-11-02.md"

export default function AboutContentNyc() {
  return (
    <ContentPageDiv>
      <StandardContent>
        <PageTitleWithImage
          preTitle="About"
          title="NYC Vintage Map"
          imageUrl={logo}
          innerShadow={false}
        />

        <ContentPageStyles>
          <Markdown>{aboutNycMd}</Markdown>
        </ContentPageStyles>
      </StandardContent>
    </ContentPageDiv>
  )
}

const ContentPageDiv = styled.div`
  padding: 20px 0 0;
`
