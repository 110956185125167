import React from "react"
import { Link } from "gatsby"

import ContentContainer from "../../containers/ContentContainer"
import BackLink from "src/components/BackLink"
import AboutContent from "./AboutContent"
import Footer from "src/components/Footer"
import StandardContent from "src/components/StandardContent"
import styled from "styled-components"
import { primaryColor } from "src/styles/styleConstants"

export default function About() {
  return (
    <>
      <ContentContainer>
        <BackLink link="/" text="Back" />
        <AboutContent />
        <StandardContent>
          <LogoAndImagesLink>
            For {process.env.GATSBY_CITY.toUpperCase()} Vintage Map logo and
            images, check out the{" "}
            <Link to="/logo-and-images">Logo and Images page</Link>.
          </LogoAndImagesLink>
        </StandardContent>
      </ContentContainer>

      <Footer />
    </>
  )
}

const LogoAndImagesLink = styled.div`
  margin: -20px 0 40px 0;
  font-size: 15px;
  line-height: 20px;

  & a {
    color: ${primaryColor};
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`
