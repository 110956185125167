import React from "react"

import About from "./About"

export interface AboutContainerProps {
  pageContext: { [key: string]: any }
}

const AboutContainer: React.FC<AboutContainerProps> = ({ pageContext }) => {
  return <About {...pageContext} />
}

export default AboutContainer
